import React from "react";
import Footer from "../../layouts/partials/footer";
import Header from "../../layouts/partials/header/Header";
import Banner from "../../components/banner";
import FeaturesPart from "../../components/featuresPart";

class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <Banner />
        <FeaturesPart />
        <Footer />
      </React.Fragment>
    );
  }
}

export default Home;
