import React from "react";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";

class PartialNav extends React.Component {
  render() {
    return (
      <>
        <Nav className="mr-auto">
          <li className="nav-item">
            <a className="nav-link" href={"#nosotros"}>
              <div style={{ fontSize: 24 }}>Nosotros</div>
            </a>
          </li>
        </Nav>
      </>
    );
  }
}

export default PartialNav;
