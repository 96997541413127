import React from "react";
import Slider from "react-slick";
import { Container, Row, Col } from "react-bootstrap";

class Banner extends React.Component {
  render() {
    var settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
    };

    return (
      <React.Fragment>
        <div
          className="banner-area-3 bg_cover"
        >
          <div className="banner-overlay">
            <img
              className="item-1"
              src="assets/images/banner-shape-1.png"
              alt=""
            />
            <img
              className="item-2"
              src="assets/images/banner-shape-2.png"
              alt=""
            />
            <Container>
              <Row className="align-items-center">
                <Col lg="12">
                  <div className="banner-content text-center">
                    <span>Software y Data Science</span>
                    <h1 className="title">Soluciones Prez</h1>
                    <p>Trabaja con profesionales de la industria</p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>

        <Slider className="brand-area brand-active" {...settings}>
          {/* <div className="brand-item">
            <img src="assets/images/brand-1.png" alt="" />
          </div> */}
          <div className="brand-item">
            <a href="https://approvecha.cl" target="_blank">
              <img src="assets/images/approvecha-logo-small.png" alt="" />
            </a>
          </div>
        </Slider>
      </React.Fragment>
    );
  }
}

export default Banner;
