import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Home from "./pages/home";
import NoMatch from "./pages/errors/no-match";
import Preloader from "./components/preloader";

class App extends React.Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <Preloader />
        <Switch>
          <Route exact path="" component={Home} />
          <Route component={NoMatch} />
        </Switch>
        <ToastContainer />
      </BrowserRouter>
    );
  }
}

export default App;
