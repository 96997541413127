import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

class Footer extends React.Component {
  render() {
    return (
      <React.Fragment>
        <footer
          className="footer-area footer-area-2 footer-area-1 bg_cover"
          style={{ backgroundImage: "url(assets/images/footer-bg.jpg)" }}
        >
          <div className="footer-overlay">
            <Container className="position-relative">
              <Row>
                <Col lg="4" md="7">
                  <div className="widget-item-1 mt-30">
                    <img src="/assets/images/logo-1.1.png" alt="" />
                    En los últimos tres años, la ciencia de datos y la
                    ingeniería de software han experimentado una profunda
                    transformación. Los avances en inteligencia artificial, la
                    computación en la nube y las metodologías ágiles han
                    impulsado cambios significativos. La ciencia de datos ahora
                    abarca modelos de aprendizaje profundo y procesamiento de
                    lenguaje natural, mientras que la ingeniería de software
                    adopta DevOps y automatización para entregas más eficientes.
                    La colaboración interdisciplinaria entre científicos de
                    datos y desarrolladores es fundamental, con un enfoque
                    compartido en la ética y la privacidad de los datos en un
                    mundo interconectado.
                  </div>{" "}
                  {/* widget item 1 */}
                </Col>
                <Col lg="3" md="5" className="offset-lg-1" />
                <Col lg="4" md="6">
                  <div className="widget-item-2 widget-item-3 mt-30">
                    <h4 className="title">Horario</h4>
                    <ul>
                      <li>Lunes - Jueves: 18:00 - 21:00</li>
                      <li>Viernes: 17:00 - 21:00</li>
                      <li>Sábados: 16:00 - 20:00</li>
                    </ul>
                    <p>
                      <span>Más de 6 años de experiencia,</span> Soluciones Prez es un partner
                      confiable, siempre responsable con y al servicio de sus clientes.
                    </p>
                    <Link to={"#be"}>
                      <i className="fal fa-angle-right" />
                      Contrata tu primera consulta
                    </Link>
                  </div>{" "}
                  {/* widget item 3 */}
                </Col>
              </Row>{" "}
              {/* row */}
              <Row>
                <Col lg="12">
                  <div className="footer-copyright">
                    <p>
                      Copyright By@ <span>Ducor</span> -{" "}
                      {new Date().getFullYear()}
                    </p>
                  </div>{" "}
                  {/* footer copyright */}
                </Col>
              </Row>{" "}
              {/* row */}
            </Container>{" "}
            {/* container */}
          </div>
        </footer>
        {/*====== BACK TO TOP ======*/}
        <div className="back-to-top back-to-top-2">
          <a href="#backTotop">
            <i className="fas fa-arrow-up" />
          </a>
        </div>
        {/*====== BACK TO TOP ======*/}
      </React.Fragment>
    );
  }
}

export default Footer;
