import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Row, Col } from "react-bootstrap";

class FeaturesPart extends React.Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal = (event) => {
    this.setState({ isOpen: true });
    event.preventDefault();
  };

  formHandle = (event) => {
    toast.success("Pronto te contactarémos!");
    event.preventDefault();
  };

  render() {
    return (
      <div id="nosotros" className="features-area pt-120 pb-120">
        <Container>
          <Row>
            <Col lg="12">
              <div className="features-item">
                <h2 className="title">Experiencia y Vanguardia</h2>
                <p>
                  En el competitivo mundo tecnológico, destaca "Soluciones
                  Ingenieriles Prez", consultoría en software y data science. Su
                  equipo altamente calificado aborda desafíos con soluciones
                  creativas y efectivas, demostrando un compromiso firme con la
                  excelencia técnica. Prioriza escuchar y comprender las
                  necesidades de sus clientes, adaptando soluciones de manera
                  precisa. Además, su actitud accesible y el soporte
                  post-implementación reflejan su genuina voluntad de contribuir
                  al éxito de los clientes y mantener relaciones duraderas. En
                  resumen, encarna la armonía entre la tecnología de vanguardia
                  y un trato humano excepcional.
                </p>
                <img
                  className="d-none d-lg-block"
                  src="assets/images/experience-photo.png"
                  alt=""
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="4" md="6">
              <div className="single-features mt-30">
                <img src="assets/images/services-1.png" alt="" />
                <h4 className="title">Software Design</h4>
                <p>
                  Convirtiendo ideas en código y desafíos en soluciones
                  digitales.
                </p>
                <Link to={"services-details"}>
                  <i className="fal fa-angle-right" /> Consúltanos
                </Link>
              </div>
            </Col>
            <Col lg="4" md="6">
              <div className="single-features mt-30">
                <img src="assets/images/services-2.png" alt="" />
                <h4 className="title">Data Analysis</h4>
                <p>
                  Navegando por mares de información, cartografiando patrones
                  ocultos y guiando decisiones con brújula estadística.
                </p>
                <Link to={"services-details"}>
                  <i className="fal fa-angle-right" /> Consúltanos
                </Link>
              </div>
            </Col>
            <Col lg="4" md="6">
              <div className="features-sub mt-30">
                <h5 className="title">Primera Consulta</h5>
                <p>Hablémos de tu negocio</p>
                <form action="index" onSubmit={this.formHandle}>
                  <div className="input-box">
                    <input type="text" placeholder="Ingresa tu correo_" />
                    <button type="submit" className="main-btn">
                      Contratar
                    </button>
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default FeaturesPart;
